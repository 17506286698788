<template>
	<div class="p-1">
        <div class="flex flex-wrap mb-2">
            <a-button @click="$router.back()">
                Regresar
            </a-button>
        </div>
        <a-card v-if="teacher">
            <template #header>
                <div class="flex justify-between">
                    <div class="flex">
                        <div class="mr-2 mb-2">
                            <img :src="teacher.profile_picture || '@/assets/images/default_image.png'" style="width: 72px; height: 72px; background: slategray; border-radius: 50%;" />
                        </div>
                        <div>
                            <h3 class="text-black" style="font-size: 16px; margin-bottom: 0;">
                                {{ `${teacher.first_name} ${teacher.last_name}` }}
                            </h3>
                            <h4>{{ teacher.is_coordinator ? lang.app.roles.coordinator : lang.app.roles.teacher }}</h4>
                            <p>{{ teacher.id_document }}</p>
                        </div>
                    </div>
                </div>
            </template>

            <div class="row px-3">
                <div class="col">
                    <div class="form-group">
                        <label for="">Fecha de inicio de actividades</label>
                        <input type="date">
                    </div>
                </div>
            </div>

            <a-table :columns="columns" :source="source" overflow>
                <template #td(specialization)="{ item, index }">
                    <a-select filterable :source="specializations" v-model="selections[index].specialization" />
                </template>
                <template #td(course)="{ item }">
                    <a-select filterable :source="courses" v-model="selections[index].course" />
                </template>
                <template #td(semester)="{ item }">
                    <a-input readonly bordered alignment="center" v-model="selections[index].semester" />
                </template>
                <template #td(section)="{ item }">
                    <a-select filterable :source="sections" v-model="selections[index].section"/>
                </template>
            </a-table>

            <template #footer>
                <a-button class="mr-2">
                    Guardar cambios
                </a-button>

                <a-button outlined :to="{ name: 'teachers' }">
                    Cancelar
                </a-button>
            </template>
        </a-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    data: () => ({
        columns: [
            { title: 'Carrera', key: 'specialization', slot: 'specialization' },
            { title: 'Materia', key: 'course', slot: 'course' },
            { title: 'Semestre', key: 'semester', slot: 'semester', style: () => ({ width: `120px` }) },
            { title: 'Sección', key: 'section', slot: 'section' },
        ],
        source: [
            { specialization: '', course: '', semester: '', section: '' },
            { specialization: '', course: '', semester: '', section: '' },
            { specialization: '', course: '', semester: '', section: '' },
            { specialization: '', course: '', semester: '', section: '' },
        ],
        selections: [
            { specialization: 1, course: 1, semester: '202002', section: 1 },
            { specialization: 1, course: 1, semester: '202002', section: 1 },
            { specialization: 1, course: 1, semester: '202002', section: 1 },
            { specialization: 1, course: 1, semester: '202002', section: 1 },
        ]
    }),
    computed: {
        ...mapState({
            teachers: state => state.teachers.pagination.data,
            teacher: ({ teachers }) => teachers.current,
            sections: state => state.sections.all.map(section => ({ label: `Sección ${section.letter_code}`, value: section.id })),
            courses: state => state.courses.all.map(course => ({ label: `${course.title}`, value: course.id })),
            specializations: state => state.specializations.all.map(specialization => ({ label: specialization.title, value: specialization.id }))
        }),
        ...mapGetters({
            lang: 'lang'
        })
    },
    methods: {
        ...mapActions({
            fetchTeacher: 'teachers/show',
            fetchSpecializations: 'specializations/fetchSpecializations',
            fetchCourses: 'courses/index',
            fetchSections: 'sections/fetchAll',
        }),
        ...mapMutations({
            setTeacher: 'teachers/SET_TEACHER',
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        })
    },
    mounted() {

        const { id } = this.$route.params

        const found = this.teachers.find(teacher => teacher.id === parseInt(id))
        if (found) {
            this.setNavbarTitle(['Listado de profesores', `${found.first_name} ${found.last_name}`, 'Materias'])
            this.setTeacher(found)
        } else {
            this.fetchTeacher({ id })
        }

        this.fetchCourses({ limit: 0 })
        this.fetchSpecializations({ limit: 0 })
        this.fetchSections({ limit: 0 })
    }
}
</script>

<style scoped>

</style>
