var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1"},[_c('div',{staticClass:"flex flex-wrap mb-2"},[_c('a-button',{on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Regresar ")])],1),(_vm.teacher)?_c('a-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mr-2 mb-2"},[_c('img',{staticStyle:{"width":"72px","height":"72px","background":"slategray","border-radius":"50%"},attrs:{"src":_vm.teacher.profile_picture || '@/assets/images/default_image.png'}})]),_c('div',[_c('h3',{staticClass:"text-black",staticStyle:{"font-size":"16px","margin-bottom":"0"}},[_vm._v(" "+_vm._s(((_vm.teacher.first_name) + " " + (_vm.teacher.last_name)))+" ")]),_c('h4',[_vm._v(_vm._s(_vm.teacher.is_coordinator ? _vm.lang.app.roles.coordinator : _vm.lang.app.roles.teacher))]),_c('p',[_vm._v(_vm._s(_vm.teacher.id_document))])])])])]},proxy:true},{key:"footer",fn:function(){return [_c('a-button',{staticClass:"mr-2"},[_vm._v(" Guardar cambios ")]),_c('a-button',{attrs:{"outlined":"","to":{ name: 'teachers' }}},[_vm._v(" Cancelar ")])]},proxy:true}],null,false,3437334599)},[_c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha de inicio de actividades")]),_c('input',{attrs:{"type":"date"}})])])]),_c('a-table',{attrs:{"columns":_vm.columns,"source":_vm.source,"overflow":""},scopedSlots:_vm._u([{key:"td(specialization)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('a-select',{attrs:{"filterable":"","source":_vm.specializations},model:{value:(_vm.selections[index].specialization),callback:function ($$v) {_vm.$set(_vm.selections[index], "specialization", $$v)},expression:"selections[index].specialization"}})]}},{key:"td(course)",fn:function(ref){
var item = ref.item;
return [_c('a-select',{attrs:{"filterable":"","source":_vm.courses},model:{value:(_vm.selections[_vm.index].course),callback:function ($$v) {_vm.$set(_vm.selections[_vm.index], "course", $$v)},expression:"selections[index].course"}})]}},{key:"td(semester)",fn:function(ref){
var item = ref.item;
return [_c('a-input',{attrs:{"readonly":"","bordered":"","alignment":"center"},model:{value:(_vm.selections[_vm.index].semester),callback:function ($$v) {_vm.$set(_vm.selections[_vm.index], "semester", $$v)},expression:"selections[index].semester"}})]}},{key:"td(section)",fn:function(ref){
var item = ref.item;
return [_c('a-select',{attrs:{"filterable":"","source":_vm.sections},model:{value:(_vm.selections[_vm.index].section),callback:function ($$v) {_vm.$set(_vm.selections[_vm.index], "section", $$v)},expression:"selections[index].section"}})]}}],null,false,1102681235)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }